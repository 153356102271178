let localStorageArray = JSON.parse(localStorage.getItem("Array")) || []

function hideSandwich() {
    document.getElementById("sandwichCheckBox").checked = false
}

function scrollToProducts() {
    document.getElementById("products-span")?.scrollIntoView({behavior: "smooth"})
}

function showHideFabricsItems() {
    let elem = document.getElementById("hamburger-nav-fabrics-items")
    elem.style.display = elem.style.display !== "none" || elem.style.display === "" ? "none" : "block"
}

function calculateTotalItemsForBasketIcon() {
    let totalQuantity = 0
    for (let item of localStorageArray) {
        totalQuantity += item[5]
    }
    document.getElementById("basket-icon-quantity-id").innerText = localStorageArray.length
    document.getElementById("basket-icon-quantity-id-mobile").innerText = localStorageArray.length
}

function reveal() {
    var reveals = document.querySelectorAll(".reveal")
    var windowHeight = window.innerHeight

    for (var i = 0; i < reveals.length; i++) {
        if (reveals[i].getBoundingClientRect().top < windowHeight * 0.85) {
            reveals[i].classList.add("active-reveals")
        } else {
            reveals[i].classList.remove("active-reveals")
        }
    }
}

window.addEventListener("scroll", reveal)

function openNav() {
    document.getElementById("mySidenav").style.width = "250px"
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0"
}

async function fetchItems() {
    try {
        const response = await fetch("/data/itemList.json")
        const itemList = await response.json()
        displayItems(itemList)
    } catch (error) {
        console.error("Error fetching items:", error)
    }
}

function displayItems(itemList) {
    const container = document.getElementById("products-container")
    container.innerHTML = ""

    itemList.forEach(item => {
        const itemHtml = `
            <a class="product-container" href="${item.path}">
                <div class="product-img-container">
                    <img class="product-img" src="${item.imagePath}" alt="${item.imageAlt}">
                    <div class="product-img-text">
                        <p>Quick View</p>
                    </div>
                </div>
                <h3>${item.name}</h3>
                <h2>${item.price}</h2>
            </a>
        `
        container.innerHTML += itemHtml
    })
}

document.addEventListener("DOMContentLoaded", fetchItems)
